import { generateClasses } from "@supercoder.dev/bem-classes"
import React, { Component } from "react"

export class Header extends Component {
  classes = generateClasses.bind(this)({
    name: "header",
    elements: ["title", "author", "description"],
  })

  render() {
    const { elements } = this.classes
    const { title, credit, html } = this.props
    return (
      <header className={this.classes.name}>
        <h1 className={elements.title}>{title}</h1>
        <span className={elements.author}>{credit}</span>
        {html && (
          <p
            className={elements.description}
            dangerouslySetInnerHTML={{ __html: html }}
          />
        )}
      </header>
    )
  }
}

export default Header
