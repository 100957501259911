import React, { Component } from "react"
import Image from "gatsby-image"
import { graphql } from "gatsby"
import { generateClasses } from "@supercoder.dev/bem-classes"
import Header from "./Header"
import Layout from "../../components/Layout"

export class GalleryItem extends Component {
  classes = generateClasses({
    name: "gallery-item",
    elements: [
      {
        name: "content",
        elements: ["download"],
      },
      { name: "image" },
    ],
  })

  render() {
    const { frontmatter, html } = this.props.data.markdownRemark
    const { image, title, credit } = frontmatter
    return (
      <Layout>
        <div className={this.classes.name}>
          {image && (
            <Image
              className={this.classes.elements.image}
              fluid={image.childImageSharp.fluid}
              alt={title}
            />
          )}
          <div className={this.classes.elements.content.name}>
            <Header
              title={title}
              html={html}
              credit={credit}
              parentClass={this.classes.elements.content.name}
            />
            <a
              href={image.childImageSharp.fluid.src}
              download={title}
              className={this.classes.elements.content.elements.download}
            >
              Download
            </a>
          </div>
        </div>
      </Layout>
    )
  }
}

export const query = graphql`
  query GalleryItem($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        credit
        image {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default GalleryItem
